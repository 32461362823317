import {CssBaseline, createTheme, ThemeProvider} from "@mui/material"
import React from "react"
import ReactDOM from "react-dom/client"
import {Provider} from "react-redux"
import App from "./App"
import {store} from "./redux/store"
import reportWebVitals from "./reportWebVitals"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/700.css"
import "./assets/css/index.css"
import {BrowserRouter} from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const theme = createTheme({
    typography: {
        fontFamily: ["Montserrat", "sans-serif"].join(",")
    }
})
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        {/* <CssBaseline /> */}
        <BrowserRouter basename="">
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
