import React, {useCallback, useEffect, useState} from "react"
import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid"
import PropTypes from "prop-types"
import "./staff.css"
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {userRolesService} from "services"
import IOSSwitch from "components/IOSSwitch"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import DeleteConfirmation from "components/deleteConfirmation"
import {toastSuccess} from "helpers/toastHelper"
import {useAuth} from "hooks/useAuth"
import {CustomNoRowsOverlay} from "components/NORowsSVG"
import {selectQuotaDetails} from "redux/features/chatbotSlice"
import {useTypedSelector} from "redux/store"

const Staff = () => {
    const [userList, setUserList] = useState([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(20)
    const [isOpen, setIsOpen] = useState(false)
    const [isListLoading, setIsListLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [isEditForm, setIsEditForm] = useState(false)
    const [userEditId, setUserEditId] = useState(null)
    const [rawUserData, setRawUserData] = useState([])
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [isAlertSwitching, setIsAlertSwitching] = useState(false)
    const quotaDetails = useTypedSelector(selectQuotaDetails)

    useEffect(() => {
        setIsListLoading(true)
        getUserList()
    }, [])

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            cellClassName: "capitalize",
            flex: 1
        },
        {
            field: "email",
            headerName: "Email ID",
            flex: 1
        },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
            cellClassName: "capitalize"
        },
        {
            field: "isActive",
            headerName: "Status",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <IOSSwitch
                        checked={cellValues?.row?.isActive}
                        onChange={(event) => setIsAlertOpen(true)}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        color="success"
                    />
                )
            }
        },
        {
            field: "edit",
            headerName: "Action",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <IconButton color="primary" aria-label="edit" onClick={() => handleEdit(cellValues?.row?.edit)}>
                        <BorderColorIcon fontSize="inherit" />
                    </IconButton>
                )
            }
        }
    ]

    const handleEdit = (userId) => {
        setIsEditForm(true)
        setIsOpen(true)
        setUserEditId(userId)
    }

    const onClose = () => {
        setIsOpen(false)
        setIsEditForm(false)
    }

    const getUserList = async (_page = page, _pageSize = pageSize) => {
        let payload = {
            filter: {
                roleId: [2, 4]
            },
            range: {
                page: _page,
                pageSize: _pageSize
            }
        }
        const response = await userRolesService.getUserList(payload)
        setRawUserData(response?.data)
        const userListData = response?.data?.map((user) => ({
            name: `${user?.firstName || ""} ${user?.lastName || ""}`.trim(),
            email: user?.email,
            role: user?.role?.roleName,
            isActive: user?.status,
            edit: user?.userId
        }))

        setUserList(userListData)

        setTotal(response?.total)
        setIsListLoading(false)
    }

    const onModelChange = (event) => {
        setPage(event.page + 1)
        setPageSize(event.pageSize)

        getUserList(event.page + 1, event.pageSize)
    }

    const handleSwitch = async () => {
        setIsAlertSwitching(true)
        await userRolesService.switchActiveStatus()
        await getUserList()
    }

    return (
        <>
            <div
                className="project_lists"
                style={{marginTop: `${quotaDetails?.data?.isQuestionQuotaToBeExpired && "2rem"}`}}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="my-4 mx-2">Staff</h4>
                        <button onClick={() => setIsOpen((prev) => !prev)} className="send-button">
                            Add New
                        </button>
                    </div>
                    <div className="mt-2 p-3 content-form-card">
                        <div className="staffTable">
                            <DataGrid
                                style={{border: 0}}
                                pagination
                                paginationMode="server"
                                rows={userList}
                                columns={columns}
                                getRowId={(row) => `${row.socketId}_${Math.floor(Math.random() * 100000000)}`}
                                loading={isListLoading}
                                onPaginationModelChange={onModelChange}
                                rowCount={Number(total) || 0}
                                initialState={{
                                    pagination: {
                                        paginationModel: {page, pageSize}
                                    }
                                }}
                                pageSizeOptions={[10, 20, 30, 40, 50]}
                                localeText={{noRowsLabel: "No Staff Found"}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && (
                <AddNewUserForm
                    onClose={onClose}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    getUserList={getUserList}
                    isEditForm={isEditForm}
                    userEditId={userEditId}
                    userList={rawUserData}
                />
            )}
            {isAlertOpen && (
                <DeleteConfirmation
                    onCancel={() => setIsAlertOpen(false)}
                    isOpen={isAlertOpen}
                    onAccept={handleSwitch}
                    onLoading={isAlertSwitching}
                />
            )}
        </>
    )
}

export default Staff

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

type BootstrapDialogTitleProps = {
    children: React.ReactNode
    onClose?: () => void
}

function BootstrapDialogTitle(props: BootstrapDialogTitleProps) {
    const {children, onClose, ...other} = props
    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

const AddNewUserForm = React.memo(
    ({
        onClose = () => null,
        isOpen = false,
        setIsOpen,
        getUserList,
        isEditForm = false,
        userEditId = null,
        userList = []
    }: any) => {
        const [userData, setUserData] = useState({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: 4
        })
        const {firstName, lastName, email, password, role} = userData
        const [clientId, setClientId] = useState(null)
        const [dob, setDOB] = useState(null)
        const {user} = useAuth()

        useEffect(() => {
            setClientId(user?.clientId)
            setDOB(user?.dob)
            getRoles()
            if (isEditForm) {
                fillEditDetails()
            }
        }, [])

        const handleSubmit = async (e) => {
            e.preventDefault()
            const commonPayload = {
                firstName: firstName,
                lastName: lastName,
                roleId: Number(role)
            }
            if (!isEditForm) {
                const payload = {
                    clientId,
                    dob,
                    email: email,
                    password: password,
                    ...commonPayload
                }
                const response = await userRolesService.createUser(payload)
                toastSuccess(response?.data?.message ?? "User created successfully!")
            } else {
                const payload = {
                    userId: userEditId,
                    ...commonPayload
                }
                const response = await userRolesService.updateUser(payload)
                toastSuccess(response?.data?.message ?? "User updated successfully!")
            }
            getUserList()
            setIsOpen(false)
            onClose()
        }

        const handleChange = (e) => {
            setUserData((prev) => ({...prev, [e.target.name]: e.target.value}))
        }

        const [roles, setRoles] = useState([])

        async function getRoles() {
            const response = await userRolesService.getRoleList()
            setRoles(response?.data)
        }

        const fillEditDetails = async () => {
            const editUser = await userList?.filter((user) => user.userId === userEditId)
            setUserData((prev) => ({
                ...prev,
                firstName: editUser[0]?.firstName,
                lastName: editUser[0]?.lastName,
                email: editUser[0]?.email,
                role: editUser[0]?.role?.roleId
            }))
        }

        return (
            <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
                <BootstrapDialogTitle onClose={onClose}>{isEditForm ? "Edit " : "Add "} Staff</BootstrapDialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit} className="add-project">
                        <div className="row">
                            <div className="form-group mb-4 col-lg-12">
                                <label htmlFor="">First Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter First Name"
                                    className="roleInputForm"
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-4 col-lg-12">
                                <label htmlFor="">Last Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter Last Name"
                                    className="roleInputForm"
                                    name="lastName"
                                    value={lastName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group mb-4 col-lg-12">
                                <label htmlFor="">Email</label>
                                <input
                                    readOnly={isEditForm ? true : false}
                                    type="email"
                                    placeholder="Enter Email"
                                    className="roleInputForm"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                />
                            </div>
                            {!isEditForm && (
                                <div className="form-group mb-4 col-lg-12">
                                    <label htmlFor="">Password</label>
                                    <input
                                        type="password"
                                        placeholder="Enter Password"
                                        className="roleInputForm"
                                        name="password"
                                        value={password}
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                            <div className="form-group mb-4 col-lg-12">
                                <label>Select Role</label>
                                <select className="form-select" name="role" value={role} onChange={handleChange}>
                                    {roles?.map(
                                        (role) =>
                                            role?.roleId !== 1 && (
                                                <option value={role?.roleId} key={role?.roleId}>
                                                    {role?.roleName}
                                                </option>
                                            )
                                    )}
                                </select>
                            </div>
                        </div>
                        <DialogActions>
                            <Button
                                className="save-btn mx-auto"
                                type="submit"
                                //   disabled={isSubmitting}
                            >
                                {/* {isSubmitting ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                "Submit"
              )} */}
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </BootstrapDialog>
        )
    }
)
