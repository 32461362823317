import {useState} from "react"
import {Navigate, Outlet, useLocation} from "react-router-dom"
import {Box, Toolbar} from "@mui/material"
import colorConfigs from "../../../configs/colorConfigs"
import sizeConfigs from "../../../configs/sizeConfigs"
import Sidebar from "../../common/Sidebar"
import Topbar from "../../common/Topbar"
import {getToken} from "helpers/storage"
import {styled, useTheme} from "@mui/material/styles"
import Drawer from "@mui/material/Drawer"
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
// <----------themeSide-------->
const drawerWidth = 300

const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})<{
    open?: boolean
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: 0
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative"
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: drawerWidth
    })
}))

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
}))

// <------------themeSide-----end---------->

const MainLayout = () => {
    const [open, setOpen] = useState(true)
    const [themeSidebarOpen, setThemeSidebarOpen] = useState(false)
    let location = useLocation()
    const token = getToken()
    const theme = useTheme()

    if (!token) {
        return <Navigate to="/login" state={{from: location}} replace />
    }

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={{display: "flex"}}>
            <Topbar open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
            <Box
                component="nav"
                sx={{
                    width: !!open ? sizeConfigs.sidebar.width : `calc(${theme.spacing(7)} + 20px)`,
                    flexShrink: 0,
                    backgroundColor: colorConfigs.mainBg
                }}>
                <Sidebar open={open} />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: open
                        ? `calc(100% - ${sizeConfigs.sidebar.width})`
                        : `calc(100% - ${theme.spacing(7)} + 20px)`,
                    minHeight: "100vh",
                    backgroundColor: colorConfigs.mainBg
                }}>
                {/* <Toolbar /> */}
                <Outlet />
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: drawerWidth
                        }
                    }}
                    variant="persistent"
                    anchor="right"
                    open={themeSidebarOpen}>
                    <DrawerHeader className="justify-content-between align-items-center">
                        <h5 className="m-0" style={{color: "#474747", fontWeight: 500}}>
                            Appearance
                        </h5>
                        <IconButton onClick={() => setThemeSidebarOpen(false)} className="colorsDrawer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-x">
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                            </svg>
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <div className="theme-cards">
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                        <div className="inner" title="Default"></div>
                    </div>
                </Drawer>
                <button className="theme-button" type="button" onClick={() => setThemeSidebarOpen(true)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="lucide lucide-settings">
                        <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                </button>
            </Box>
        </Box>
    )
}

export default MainLayout
