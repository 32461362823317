import BaseService from "./Base"
import ApiUrls from "./ApiUrl"
import {toastError} from "helpers/toastHelper"
import {ProjectBodyParam} from "types/Projects"

export default class ProjectService extends BaseService {
    async getProjects(param: ProjectBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.projectList, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async deleteProject(params = {}) {
        try {
            const response = await this.post(ApiUrls.projectDelete, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async createProject(params = {}) {
        try {
            const response = await this.post(ApiUrls.projectCreate, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updateProject(params = {}) {
        try {
            const response = await this.post(ApiUrls.projectUpdate, params)
            return response
        } catch (error) {
            throw error
        }
    }
}
