import {lazy} from "react"
import {Loadable, MainLayout, MinimalLayout} from "../components"

const PopupChatBot = Loadable(lazy(() => import("../pages/chatbots/popup-bot")))
const Error404 = Loadable(lazy(() => import("../pages/auth/404")))

// ==============================|| MAIN ROUTING ||============================== //

const FullPageRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "popup-chatbot",
            element: <PopupChatBot />
        },
        {
            path: "*",
            element: <Error404 />
        }
    ]
}

export default FullPageRoutes
