import {Drawer as MuiDrawer, List, Toolbar, Stack, Avatar, Box, CircularProgress, Typography} from "@mui/material"
import assets from "../../assets"
import colorConfigs from "../../configs/colorConfigs"
import sizeConfigs from "../../configs/sizeConfigs"
import appRoutes, {staffRoutes, superAdminRoutes} from "../../routes/appRoutes"
import SidebarItem from "./SidebarItem"
import SidebarItemCollapse from "./SidebarItemCollapse"
import {styled, useTheme, Theme, CSSObject} from "@mui/material/styles"
import "./styles.css"
import {useAuth} from "hooks/useAuth"
import {useEffect, useState} from "react"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import {Link, useLocation} from "react-router-dom"
import {useAppDispatch, useTypedSelector} from "redux/store"
import {getQuotaDetailFromStore, selectQuotaDetails} from "redux/features/chatbotSlice"
import {selectCurrentUser, selectIsShowSuperAdminButton} from "redux/features/appStateSlice"

const openedMixin = (theme: Theme): CSSObject => ({
    width: sizeConfigs.sidebar.width,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden"
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 20px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 20px)`
    }
})

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 0),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
}))

const DrawerFooter = styled("div")(() => ({
    display: "flex",
    alignItems: "end",
    paddingBottom: "30px",
    justifyContent: "center",
    height: "100%"
}))

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(({theme, open}) => ({
    width: sizeConfigs.sidebar.width,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
    })
}))

const Sidebar = ({open = false}: any) => {
    const theme = useTheme()
    const auth = useAuth()
    const clientType = process.env.REACT_APP_CLIENT_TYPE || "default"
    const [colors, setColors] = useState(colorConfigs.default)
    const [animatedValue, setAnimatedValue] = useState(0)

    const quotaDetails = useTypedSelector(selectQuotaDetails)
    const isShowSuperAdminButton = useTypedSelector(selectIsShowSuperAdminButton)
    const userDetails = useTypedSelector(selectCurrentUser)
    const dispatch = useAppDispatch()
    const location = useLocation()

    useEffect(() => {
        setColors(colorConfigs.default)
        if (userDetails?.roleId && userDetails?.roleId !== 1) {
            dispatch(getQuotaDetailFromStore({}))
        }
    }, [dispatch, location, clientType, userDetails])

    useEffect(() => {
        const targetValue = parseFloat(
            quotaDetails?.data?.percentageUsed ? quotaDetails?.data?.percentageUsed.replace("%", "") : 0
        )
        let startValue = 0
        const duration = 1000
        let animationFrame

        const animate = (currentTime) => {
            const elapsedTime = currentTime - startTime
            const progress = Math.min(elapsedTime / duration, 1)
            const currentValue = startValue + (targetValue - startValue) * progress

            setAnimatedValue(currentValue)

            if (progress < 1) {
                animationFrame = requestAnimationFrame(animate)
            } else {
                setAnimatedValue(targetValue)
            }
        }

        const startTime = performance.now()
        animationFrame = requestAnimationFrame(animate)

        return () => cancelAnimationFrame(animationFrame)
    }, [quotaDetails])

    const renderSideRoute = (routes = []) => {
        if (!routes || !routes.length) return ""
        return routes.map((route, index) =>
            route.sidebarProps ? (
                route.child ? (
                    <SidebarItemCollapse item={route} key={index} open={open} />
                ) : (
                    <SidebarItem item={route} key={index} open={open} />
                )
            ) : null
        )
    }

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    borderRight: "0px",
                    color: colors.sidebar.color,
                    background: colors.sidebar.gradientColorSidebar
                }
            }}
            variant="permanent"
            anchor="left"
            open={open}>
            <div>
                <DrawerHeader className="logo-area mb-0">
                    {open ? (
                        <>
                            {!!auth?.user && (
                                <img hidden={auth?.user?.roleId != 1} src={assets.images.loginLogo} alt="admin" />
                            )}
                            {!!auth?.user && (
                                <img hidden={auth?.user?.roleId == 1} src={assets.images.loginLogo} alt="client" />
                            )}
                        </>
                    ) : (
                        <div></div>
                    )}
                </DrawerHeader>
                <List disablePadding>
                    <Toolbar sx={{marginBottom: "20px"}}>
                        <Stack
                            sx={{width: "100%", textTransform: "capitalize"}}
                            direction="column"
                            justifyContent="left"
                            alignItems="left">
                            {open ? (
                                <div className="nameSection">
                                    <div>
                                        <PersonOutlineIcon className="userIcon mb-3" />
                                        {/* <Avatar className="mb-3" src={assets.images.logo} /> */}
                                    </div>
                                    <div>
                                        <h6 className="mb-1 nameText">
                                            {auth?.user?.firstName || ""} {auth?.user?.lastName || ""}
                                        </h6>
                                        <p className="roleText">
                                            {" "}
                                            {auth?.user?.roleId == 1
                                                ? "Super Admin"
                                                : auth?.user?.roleId == 2
                                                  ? "Admin"
                                                  : auth?.user?.roleId == 4 && "Staff"}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </Stack>
                    </Toolbar>
                    {!!auth?.user &&
                        renderSideRoute(
                            auth?.user?.roleId == 1
                                ? superAdminRoutes
                                : auth?.user?.roleId == 2
                                  ? appRoutes
                                  : auth?.user?.roleId == 4 && staffRoutes
                        )}
                </List>
            </div>
            {open && (isShowSuperAdminButton || auth?.user?.roleId !== 1) && (
                <div className="storage-box">
                    <Box sx={{position: "relative", display: "inline-flex"}}>
                        <CircularProgress
                            variant="determinate"
                            className="backgroundProgressbar"
                            size={80}
                            value={100}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={animatedValue}
                            size={80}
                            className="frontProgressbar"
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "600"
                                }}>{`${quotaDetails?.data?.percentageUsed ?? 0}`}</Typography>
                        </Box>
                    </Box>
                    <h6 className="mt-2">Messages Used: {quotaDetails?.data?.askedQuestion}</h6>
                    <p>
                        Your team has used {quotaDetails?.data?.percentageUsed} of available quota,{" "}
                        {quotaDetails?.data?.totalQuestion} messages. Upgrade plan to get more space.
                    </p>
                    <Link to={"/billing/packages"}>Upgrade Plan</Link>
                </div>
            )}
        </Drawer>
    )
}

export default Sidebar
