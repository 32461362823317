import React, {useEffect, useState} from "react"
import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid"
import {
    Button,
    Checkbox,
    IconButton,
    Stack,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Tooltip
} from "@mui/material"
import {styled} from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import PropTypes from "prop-types"
import "./styles.css"
import {useForm} from "react-hook-form"
import {Error} from "components/auth/Error"
import {Input} from "components/auth/Input"
import {getPricingFeatureFromStore, selectPricingFeature} from "redux/features/pricingFeatureSlice"
import {useAppDispatch, useTypedSelector} from "redux/store"
import {CustomNoRowsOverlay} from "components/NORowsSVG"
import {getPricingFromStore, selectPricing} from "redux/features/pricingSlice"
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import {pricingFeatureService} from "services"
import {toastError, toastSuccess} from "helpers/toastHelper"
// import Pricing from './pricing';
// import Feature from './feature';

type Props = {}
const Setting = (props: Props) => {
    return (
        <>
            {/* <div>
      <Feature/>
    </div>
    <div className='mt-3'>
      <Pricing/>
    </div> */}
        </>
    )
}

export default Setting
