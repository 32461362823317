import {lazy} from "react"
import {Loadable, MinimalLayout} from "../components"
import InvalidLink from "pages/auth/invalidLink"

const AuthLogin = Loadable(lazy(() => import("../pages/auth/login")))
const AuthRegister = Loadable(lazy(() => import("../pages/auth/register")))
const Error404 = Loadable(lazy(() => import("../pages/auth/404")))
const Verify = Loadable(lazy(() => import("../pages/auth/verify")))
const ForgotPassword = Loadable(lazy(() => import("../pages/auth/forgotPassword")))
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "/",
            element: <AuthLogin />
        },
        {
            path: "login",
            element: <AuthLogin />
        },
        {
            path: "signup",
            element: <AuthRegister />
        },
        {
            path: "verify",
            element: <Verify />
        },
        {
            path: "forgot-password",
            element: <ForgotPassword />
        },
        {
            path: "error",
            element: <Error404 />
        },
        {
            path: "invalid-link",
            element: <InvalidLink />
        },
        {
            path: "*",
            element: <Error404 />
        }
    ]
}

export default LoginRoutes
