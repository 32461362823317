import {styled} from "@mui/material/styles"
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import {useForm} from "react-hook-form"
import {toastError, toastSuccess} from "helpers/toastHelper"
import {useMemo, useState} from "react"
import {MultiSelect} from "react-multi-select-component"
import {Input} from "components/auth/Input"
import {clientService} from "services"
import {Error} from "components/auth/Error"
import * as React from "react"
import CircularProgress from "@mui/material/CircularProgress"

const BootstrapDialog = styled(Dialog)(({theme}: any) => ({
    "& .MuiDialogContent-root": {
        padding: theme?.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme?.spacing(1)
    }
}))

function BootstrapDialogTitle(props: any) {
    const {children, onClose, ...other} = props

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

const inputsConfig = {
    clientName: {
        name: "clientName",
        placeholder: "Enter client name",
        className: "form-control",
        type: "text",
        required: "Client name is required"
    },
    adminName: {
        name: "adminName",
        placeholder: "Enter admin name",
        className: "form-control",
        type: "text",
        required: "Admin name is required"
    },
    email: {
        name: "email",
        placeholder: "Enter email",
        className: "form-control",
        type: "email",
        required: "Email is required"
    },
    password: {
        name: "password",
        placeholder: "Enter password",
        className: "form-control",
        type: "password",
        required: "Password is required"
    },
    openAIApiKey: {
        name: "openAIApiKey",
        placeholder: "Enter Open AI API Key",
        className: "form-control",
        type: "text"
    },
    pineconeApiKey: {
        name: "pineconeApiKey",
        placeholder: "Enter Pinecone API Key",
        className: "form-control",
        type: "text"
    },
    pineconeEnvironment: {
        name: "pineconeEnvironment",
        placeholder: "Enter Pinecone Environment",
        className: "form-control",
        type: "text"
    },
    pineconeProjectId: {
        name: "pineconeProjectId",
        placeholder: "Enter Pinecone Project ID",
        className: "form-control",
        type: "text"
    }
}

export const CreateClient = ({open = false, handleClose = () => null, detail = null, getClients = () => null}) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors}
    } = useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)

    useMemo(() => {
        setValue("clientName", detail?.name)
        setValue("email", detail?.email)
        setValue("openAIApiKey", detail?.openAIApiKey)
        setValue("pineconeApiKey", detail?.pineconeApiKey)
        setValue("pineconeEnvironment", detail?.pineconeEnvironment)
        setValue("pineconeProjectId", detail?.pineconeProjectId)
    }, [detail])

    const onChange =
        (name: any) =>
        ({target}: any) => {
            setValue(name, target.value)
        }

    const onSubmit = async ({
        clientName,
        adminName,
        email,
        password,
        openAIApiKey,
        pineconeApiKey,
        pineconeEnvironment,
        pineconeProjectId
    }) => {
        try {
            setIsSubmitting(true)
            let response = null
            let payload = {
                name: clientName,
                email,
                openAIApiKey,
                pineconeApiKey,
                pineconeEnvironment,
                pineconeProjectId
            }
            if (!detail) {
                response = await clientService.createClient({
                    ...payload,
                    userDetails: {
                        firstName: adminName,
                        email,
                        password
                    }
                })
            } else if (!!detail?.clientId) {
                payload["clientId"] = detail?.clientId
                response = await clientService.updateClient(payload)
            }
            if (!!response) toastSuccess(response?.data?.message)
            getClients()
        } catch (e) {
            toastError(e["response"]?.data?.message)
            setIsSubmitting(false)
        } finally {
            setIsSubmitting(false)
        }
    }

    const renderInput = (inputProps = {}) => {
        return (
            <Input
                {...{
                    register,
                    errors,
                    watch,
                    onChange,
                    ...inputProps
                }}
            />
        )
    }

    return (
        <>
            <BootstrapDialog
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle onClose={handleClose}>
                    {detail != null ? "Update Client Details" : "Add New Client"}
                </BootstrapDialogTitle>

                <DialogContent dividers>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-project">
                        <div className="row">
                            <div className="form-group col-lg-12">
                                <label htmlFor="">Client Name</label>
                                {renderInput(inputsConfig.clientName)}
                                {!!errors.clientName && <Error message={errors.clientName.message} />}
                            </div>
                            {!detail && (
                                <div className="form-group mt-4 col-lg-12">
                                    <label htmlFor="">Admin Name</label>
                                    {renderInput(inputsConfig.adminName)}
                                    {!!errors.adminName && <Error message={errors.adminName.message} />}
                                </div>
                            )}
                            <div className="form-group mt-4 col-lg-12">
                                <label htmlFor="">Email</label>
                                {renderInput(inputsConfig.email)}
                                {!!errors.email && <Error message={errors.email.message} />}
                            </div>

                            {/* {!detail && (
                <div className="form-group mt-4 col-lg-12">
                  <label htmlFor="">Password</label>
                  {renderInput(inputsConfig.password)}
                  {!!errors.password && (
                    <Error message={errors.password.message} />
                  )}
                </div>
              )} */}

                            <div className="form-group mt-4 col-lg-12">
                                <label htmlFor="">Open AI Key</label>
                                {renderInput(inputsConfig.openAIApiKey)}
                            </div>

                            <div className="form-group mt-4 col-lg-12">
                                <label htmlFor="">Pinecone Api Key</label>
                                {renderInput(inputsConfig.pineconeApiKey)}
                            </div>

                            <div className="form-group mt-4 col-lg-12">
                                <label htmlFor="">Pinecone Environment</label>
                                {renderInput(inputsConfig.pineconeEnvironment)}
                            </div>

                            <div className="form-group mt-4 col-lg-12">
                                <label htmlFor="">Pinecone ProjectId</label>
                                {renderInput(inputsConfig.pineconeProjectId)}
                            </div>

                            {/* <div className="form-group mt-4 col-lg-12">
                <label htmlFor="">Web Hooks</label>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                />
              </div> */}
                            <div className="form-group mt-4 col-lg-12">
                                <DialogActions className="p-0">
                                    <Button disabled={isSubmitting} className="save-btn mx-auto" type="submit">
                                        {isSubmitting ? (
                                            <Box sx={{display: "flex"}}>
                                                <CircularProgress color="inherit" size={"1.5rem"} />
                                            </Box>
                                        ) : (
                                            "Submit"
                                        )}
                                    </Button>
                                </DialogActions>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </BootstrapDialog>
        </>
    )
}
