import BaseService from "./Base"
import {toastError} from "helpers/toastHelper"
import ApiUrls from "./ApiUrl"
import {ChatbotBodyParam, ChatbotCreateReq, ChatbotDeleteReq, EmbedPromptsReq} from "types/Chatbot"

export default class ChatbotService extends BaseService {
    async getPrompts(param: ChatbotBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.promptList, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async getCategories(param: ChatbotBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.categoryList, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async getChatbotByProject(param: ChatbotBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.chatbotList, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async createChatbot(params: ChatbotCreateReq) {
        try {
            const response = await this.post(ApiUrls.chatbotCreate, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async deleteChatbot(params: ChatbotDeleteReq) {
        try {
            const response = await this.post(ApiUrls.chatbotDelete, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async getEmbedPrompts(param: EmbedPromptsReq) {
        try {
            const response = await this.post(ApiUrls.getEmbedPrompts, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async createClientPromp(params = {}) {
        try {
            const response = await this.post(ApiUrls.clientPromptEmbeded + "/" + "create", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async createClientPromptEmbeddingMapping(params = {}) {
        try {
            const response = await this.post(ApiUrls.clientPromptEmbeddedMapping + "/" + "create", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async deleteClientPrompt(params = {}) {
        try {
            const response = await this.post(ApiUrls.clientPromptEmbeded + "/" + "delete", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async deleteClientPromptMapping(params = {}) {
        try {
            const response = await this.post(ApiUrls.clientPromptEmbeddedMapping + "/" + "delete", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updateClientPrompt(params = {}) {
        try {
            const response = await this.post(ApiUrls.updateClientPrompt, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async uploadFileAvatar(params = {}) {
        try {
            const response = await this.post(ApiUrls.uploadFile, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async createEmailSend(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHook + "/" + "create" + "/" + "send-email", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async createWebHooks(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHook + "/" + "create/" + "webhook", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async listWebHooks(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHook + "/" + "list", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async deleteWebHook(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHook + "/" + "delete", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updateWebHook(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHook + "/" + "update", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async flagMessage(param = {}) {
        try {
            const response = await this.post(ApiUrls.flagMessage, param)
            return response.data
        } catch (error) {
            throw error
        }
    }

    async clientPromptEmbededList(params = {}) {
        try {
            const response = await this.post(ApiUrls.clientPromptEmbeded + "/" + "list", params)
            return response
        } catch (error) {
            throw error
        }
    }

    // async listWebHookPlatform(params = {}) {
    //   try {
    //     const response = await this.post(
    //       ApiUrls.webHookPlatform + "/" + "list",
    //       params
    //     );
    //     return response;
    //   } catch (error) {
    //     throw error;
    //   }
    // }

    // async leadList(params = {}) {
    //   const response = await this.post(
    //     ApiUrls.lead + "/" + "list",
    //     params
    //   );
    //   return response;
    // }

    async conversationList(params = {}) {
        try {
            const response = await this.post(ApiUrls.conversation + "/" + "list", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async createQuestionAnswer(params = {}) {
        try {
            const response = await this.post(ApiUrls.questionAnswer + "/" + "create", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async deleteQuestionAnswer(params = {}) {
        try {
            const response = await this.post(ApiUrls.questionAnswer + "/" + "delete", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updateQuestionAnswer(params = {}) {
        try {
            const response = await this.post(ApiUrls.questionAnswer + "/" + "update", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async retrainQuestionAnswer(params = {}) {
        try {
            const response = await this.post(ApiUrls.questionAnswer + "/" + "retrain", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async QuestionAnswerList(params = {}) {
        try {
            const response = await this.post(ApiUrls.questionAnswer + "/" + "list", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async getCountToken(param: any) {
        try {
            const response = await this.post(ApiUrls.countToken, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async getParameterInputType(param: any) {
        try {
            const response = await this.post(ApiUrls.parameterInputType, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async getConversationMsg(param: any) {
        try {
            const response = await this.post(ApiUrls.conversationsDownload, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async getlanguageOptions(param: ChatbotBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.languageOptions, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async getQuotaDetails(param: any) {
        try {
            const response = await this.post(ApiUrls.quotaDetails, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async cloneBot(param = {}) {
        try {
            const response = await this.post(ApiUrls.cloneBot, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
}
