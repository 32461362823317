import React from "react"
import {CardElement, Elements} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js"

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
type Props = {}

const AdminDashboard = (props: Props) => {
    // const handleSubmit = async event => {
    //   event.preventDefault();
    //   console.log("handleSubmit========", event)
    //   // handle payment request
    // };

    // const CARD_ELEMENT_OPTIONS = {
    //   // iconStyle: "solid",
    //   hidePostalCode: true,
    //   style: {
    //     base: {
    //       iconColor: "rgb(240, 57, 122)",
    //       color: "rgb(240, 57, 122)",
    //       fontSize: "16px",
    //       fontFamily: '"Open Sans", sans-serif',
    //       fontSmoothing: "antialiased",
    //       "::placeholder": {
    //         color: "#CFD7DF"
    //       }
    //     },
    //     invalid: {
    //       color: "#e5424d",
    //       ":focus": {
    //         color: "#303238"
    //       }
    //     }
    //   }
    // };
    return (
        <div className="project_lists">
            <div className="container-fluid">
                <h4 className="my-4">Admin Dashboard</h4>
            </div>
            {/* <div>
        <Elements stripe={stripePromise}>
        <div>
          <div className="product-info">
            <h3 className="product-title">Apple MacBook Pro</h3>
            <h4 className="product-price">$999</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <button className="btn-pay">Buy Now</button>
          </form>
        </div>
        </Elements>
      </div> */}
        </div>
    )
}

export default AdminDashboard
