import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "redux/store"
import {pricingService} from "services"
import {MetaPagination} from "types/Others"
import {PricingBodyParam} from "types/pricing"

type PricingState = {
    pricing: Array<any>
    meta: MetaPagination
    total: number
}

export const getPricingFromStore = createAsyncThunk(
    "auth/getPricingFromStore",
    async (param: PricingBodyParam = {}) => {
        return await pricingService.getPricing(param)
    }
)

const initialState: PricingState = {
    pricing: [],
    meta: {},
    total: 0
}

export const pricingSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        resetPricing: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPricingFromStore.fulfilled, (state, action) => {
                const {data, meta, total} = action.payload
                state.pricing = data
                state.meta = meta
                state.total = total
            })
            .addCase(getPricingFromStore.rejected, (state, action) => {
                state.total = 0
            })
    }
})

export const {resetPricing} = pricingSlice.actions

export default pricingSlice.reducer
export const selectPricing = (state: RootState) => state.pricingState?.pricing
