import {get} from "lodash"
import BaseService from "./Base"
import ApiUrls from "./ApiUrl"
import {toastError} from "helpers/toastHelper"
import {ProjectBodyParam} from "types/Projects"

export default class PromptService extends BaseService {
    async getPrompts(param: ProjectBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.promptList, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async deletePrompt(params = {}) {
        try {
            const response = await this.post(ApiUrls.promptDelete, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async createPrompt(params = {}) {
        try {
            const response = await this.post(ApiUrls.promptCreate, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updatePrompt(params = {}) {
        try {
            const response = await this.post(ApiUrls.promptUpdate, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async genericPromptList(params = {}) {
        try {
            const response = await this.post(`${ApiUrls.genericPrompts}/list`, params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updateGenericPrompt(params = {}) {
        try {
            const response = await this.post(`${ApiUrls.genericPrompts}/update`, params)
            return response
        } catch (error) {
            throw error
        }
    }
}
