import dayjs from "dayjs"

export function getFormatedDate(date) {
    if (!date) return ""
    return dayjs(new Date(date)).format("DD/MM/YYYY")
}

export function getFormatedDateWithTime(date) {
    if (!date) return ""
    return dayjs(new Date(date)).format("DD/MM/YYYY hh:mm A")
}

export function textCapitalize(text: string) {
    if (!text) return ""
    let firstChar = text?.charAt(0)?.toUpperCase()
    if (!!text && text?.length === 1) return firstChar
    return firstChar + text?.slice(1)
}

export function exportContentInfo(userInfo: any, name = "chat-history") {
    const fileData: any = typeof userInfo != "string" ? JSON.stringify(userInfo) : userInfo
    const blob: any = new Blob([fileData], {type: "text/plain"})
    const url: any = URL.createObjectURL(blob)
    const link: any = document.createElement("a")
    link.download = `${name}.txt`
    link.href = url
    link?.click()
}
