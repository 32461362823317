import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "redux/store"
import {chatbotIntegrationService, chatbotService} from "services"
import {ChatbotBodyParam} from "types/Chatbot"
import {MetaPagination} from "types/Others"

type ChatbotState = {
    chatbots: Array<any>
    meta: MetaPagination
    total: number
    chatbotDetails: any
    isLoading: boolean
    error: any
    webHookData: any
    chatbotHeading: string
    quotaDetails: any
}

export const getChatbotFromStore = createAsyncThunk(
    "auth/getChatbotFromStore",
    async (param: ChatbotBodyParam = {}) => {
        return await chatbotService.getChatbotByProject(param)
    }
)

export const getChatbotDetailFromStore = createAsyncThunk(
    "auth/getChatbotDetailFromStore",
    async (param: ChatbotBodyParam = {}) => {
        return await chatbotService.getChatbotByProject(param)
    }
)

export const getQuotaDetailFromStore = createAsyncThunk("auth/getQuotaDetailFromStore", async (param: {}) => {
    return await chatbotService.getQuotaDetails(param)
})

export const fetchWebHookData = createAsyncThunk("auth/fetchWebHookData", async (clientPromptId: any) => {
    const payload = {filter: {clientPromptId}}
    const response = await chatbotIntegrationService.listWebHookPlatform(payload)
    if (response.data.success) {
        return response.data.data
    }
    return null
})

const initialState: ChatbotState = {
    chatbots: [],
    meta: {},
    total: 0,
    chatbotDetails: null,
    isLoading: false,
    error: "",
    webHookData: null,
    chatbotHeading: "",
    quotaDetails: ""
}

export const chatbotSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        resetChatbot: () => initialState,
        resetChatbotDetails: (state, action) => {
            state.chatbotDetails = null
        },
        updateChatbotHeading: (state, action) => {
            state.chatbotHeading = action.payload?.data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChatbotFromStore.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getChatbotFromStore.fulfilled, (state, action) => {
                const {data, meta, total} = action.payload
                state.chatbots = data
                state.meta = meta
                state.total = total
                state.isLoading = false
            })
            .addCase(getChatbotFromStore.rejected, (state, action) => {
                state.isLoading = false
                state.chatbots = []
                state.error = action.payload
            })
        builder
            .addCase(getChatbotDetailFromStore.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getChatbotDetailFromStore.fulfilled, (state, action) => {
                const {data} = action.payload
                state.chatbotDetails = data[0] || null
                state.isLoading = false
            })
            .addCase(getChatbotDetailFromStore.rejected, (state, action) => {
                state.chatbotDetails = null
                state.error = action["error"]?.message
                state.isLoading = false
            })
        builder
            .addCase(fetchWebHookData.fulfilled, (state, action) => {
                state.webHookData = action.payload
                state.isLoading = false // Set loading to false when data is successfully fetched
            })
            .addCase(fetchWebHookData.pending, (state) => {
                state.isLoading = true // Set loading to true while fetching data
            })
            .addCase(fetchWebHookData.rejected, (state, action) => {
                state.error = action.error.message // Set the error message in case of rejection
                state.isLoading = false // Set loading to false on rejection
            })
        builder
            .addCase(getQuotaDetailFromStore.fulfilled, (state, action) => {
                state.quotaDetails = action.payload
                state.isLoading = false // Set loading to false when data is successfully fetched
            })
            .addCase(getQuotaDetailFromStore.pending, (state) => {
                state.isLoading = true // Set loading to true while fetching data
            })
            .addCase(getQuotaDetailFromStore.rejected, (state, action) => {
                state.error = action.error.message // Set the error message in case of rejection
                state.isLoading = false // Set loading to false on rejection
            })
    }
})

export const {resetChatbot, resetChatbotDetails, updateChatbotHeading} = chatbotSlice.actions

export default chatbotSlice.reducer
export const selectChatbots = (state: RootState) => state.chatbotState
export const selectChatbotDetails = (state: RootState) => state.chatbotState.chatbotDetails
export const selectWebhookDetails = (state: RootState) => state.chatbotState.webHookData
export const selectChatbotError = (state: RootState) => state.chatbotState.error
export const selectChatbotHeading = (state: RootState) => state.chatbotState.chatbotHeading
export const selectQuotaDetails = (state: RootState) => state.chatbotState.quotaDetails
