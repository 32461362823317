import {get} from "lodash"
import BaseService from "./Base"
import ApiUrls from "./ApiUrl"
import {PricingBodyParam} from "types/pricing"

export default class PricingService extends BaseService {
    async getPricing(param: PricingBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.pricing + "/" + "list", param)
            return response?.data
        } catch (error) {
            throw error
        }
    }

    async createPricing(params = {}) {
        try {
            const response = await this.post(ApiUrls.pricing + "/" + "create", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updatePricing(params = {}) {
        try {
            const response = await this.post(ApiUrls.pricing + "/" + "update", params)
            return response
        } catch (error) {
            throw error
        }
    }
    async deletePricing(params = {}) {
        try {
            const response = await this.post(ApiUrls.pricing + "/" + "delete", params)
            return response
        } catch (error) {
            throw error
        }
    }
    async pricingUpdateSequence(params = {}) {
        try {
            const response = await this.post(ApiUrls.pricing + "/" + "update-sequence", params)
            return response
        } catch (error) {
            throw error
        }
    }
    //   async deleteClient(params = {}) {
    //     const response = await this.post(
    //       ApiUrls.clientDelete,
    //       params
    //     );
    //     return response;
    //   }

    //   async createClient(params = {}) {
    //     const response = await this.post(
    //       ApiUrls.clientCreate,
    //       params
    //     );
    //     return response;
    //   }

    //   async updateClient(params = {}) {
    //     const response = await this.post(
    //       ApiUrls.clientUpdate,
    //       params
    //     );
    //     return response;
    //   }
}
