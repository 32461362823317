import React, {useEffect, useState} from "react"
import "./styles.css"
import {Button} from "@mui/material"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined"
// import { BilledToInfo } from "components/clients/billedToInfo";
// import { BillMail } from "components/clients/billMail";
import {useNavigate} from "react-router-dom"
import {clientService} from "services"
import {useAppDispatch, useTypedSelector} from "redux/store"
import {getPricingFromStore, selectPricing} from "redux/features/pricingSlice"
import {selectQuotaDetails} from "redux/features/chatbotSlice"

type Props = {}
// const initialState: any = { openBillTo: false, openBillMail: false };

const Billings = (props: Props) => {
    const pricing: Array<any> = useTypedSelector(selectPricing)
    const quotaDetails = useTypedSelector(selectQuotaDetails)
    // const [{ openBillTo, openBillMail }, setState] = useState(initialState)
    const [subscriptionDetails, setSubscriptionDetails] = useState<any>({})
    const [transactionData, setTransactionData] = useState<any>({})
    const [invoiceData, setInvoiceData] = useState<any[]>([])
    const [planDetails, setPlanDetails] = useState<any>({})

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        fetchSubsData()
        getPricing()
    }, [])

    useEffect(() => {
        fetchPlanDetails()
    }, [pricing, subscriptionDetails])

    const getPricing = async () => {
        dispatch(
            getPricingFromStore({
                range: {
                    page: 1,
                    pageSize: 20
                }
            })
        )
    }

    const fetchSubsData = async () => {
        const subsData = await clientService.checkUserSubscription()
        setSubscriptionDetails(subsData?.data)
        const transactionData = await clientService.getTransactionDetails()
        setTransactionData(transactionData?.data)
        const invoiceData = await clientService.getInvoiceDetails()
        setInvoiceData(invoiceData?.data)
    }

    // const handleBilledToPopup = () => {
    //   setState((prevState) => ({
    //     ...prevState,
    //     openBillTo: !openBillTo,
    //   }));
    // };
    // const handleBillMailPopup = () => {
    //   setState((prevState) => ({
    //     ...prevState,
    //     openBillMail: !openBillMail,
    //   }));
    // };

    const clientDetails = subscriptionDetails?.clientDetails

    const detailsString = joinNonEmpty([clientDetails?.name, clientDetails?.address, clientDetails?.country])

    function joinNonEmpty(items, separator = ", ") {
        return items.filter((item) => item).join(separator)
    }

    const onPressChangePlan = () => {
        navigate("/billing/packages")
    }

    function timestampToDate(timestamp, dateFormat = "") {
        const date = dateFormat === "number" ? new Date(timestamp * 1000) : new Date(timestamp)
        const month = date.toLocaleString("default", {month: "long"})
        const day = date.getDate()
        const year = date.getFullYear()

        return `${month} ${day}, ${year}`
    }

    function downloadInvoice(url) {
        window.open(url, "_blank")
    }
    function fetchPlanDetails() {
        const plan = pricing.find((item) => item.packageId === subscriptionDetails?.subscriptionDetails?.packageId)
        setPlanDetails(plan)
    }

    return (
        <>
            <div
                className="project_lists"
                style={{marginTop: `${quotaDetails?.data?.isQuestionQuotaToBeExpired && "2rem"}`}}>
                <div className="container-fluid">
                    <h4 className="my-4 billTitle">
                        <span className="iconMargin">
                            <MonetizationOnIcon fontSize="large" style={{fill: "#FD8212"}} />
                        </span>
                        <span>Billings</span>
                    </h4>
                    <div className="_planText">
                        <div>
                            <h5>Plan</h5>
                            <span className="planDetails">{planDetails?.title}</span>
                        </div>
                        <div>
                            <span className="planDetails">
                                {" "}
                                {planDetails?.currencySymbol}
                                {planDetails?.price} per month
                                {(subscriptionDetails?.subscriptionDetails?.dueDate || "").trim() !== "" &&
                                    ` - renews ${timestampToDate(subscriptionDetails?.subscriptionDetails?.dueDate)}`}
                            </span>
                            <Button variant="contained" onClick={onPressChangePlan}>
                                Change Plan
                            </Button>
                        </div>
                    </div>
                    <hr />

                    <div className="_planText">
                        <div>
                            <h5>Payment method</h5>
                            <span className="planDetails">
                                {(transactionData?.cardType || "").trim() !== ""
                                    ? transactionData?.cardType.trim().charAt(0).toUpperCase() +
                                      transactionData?.cardType.trim().slice(1) +
                                      " Card"
                                    : ""}
                            </span>
                        </div>
                        <div>
                            <span className="planDetails">
                                {(transactionData?.cardBrand || "").trim() !== "" &&
                                    (transactionData?.cardLast4digit || "").trim() !== "" &&
                                    `${transactionData?.cardBrand?.toUpperCase()} ending with ${transactionData?.cardLast4digit}`}
                            </span>
                            {/* <Button variant="contained">Edit Method</Button> */}
                        </div>
                    </div>

                    <div className="_planText mt-4">
                        <div>
                            <h5>Billed to</h5>
                            <span className="planDetails">{detailsString}</span>
                        </div>
                        {/* <div>
              <Button variant="contained" onClick={handleBilledToPopup}>
                Edit Information
              </Button>
            </div> */}
                    </div>

                    <div className="_planText mt-4">
                        <div>
                            <h5>Billing email</h5>
                            <span className="planDetails">{subscriptionDetails?.email}</span>
                        </div>
                        {/* <div>
              <Button variant="contained" onClick={handleBillMailPopup}>
                Edit email
              </Button>
            </div> */}
                    </div>
                    <hr />

                    <div>
                        <h5 className="my-4 billTitle">
                            <span className="iconMargin">
                                <InsertDriveFileOutlinedIcon fontSize="large" style={{fill: "#FD8212", fontSize: 25}} />
                            </span>
                            <span>Invoices</span>
                        </h5>
                    </div>
                    {invoiceData?.map((item) => (
                        <div className="_planText mt-4">
                            <div>
                                <h5>{timestampToDate(item?.dataJson?.created, "number")}</h5>
                                <span className="planDetails">
                                    Paid {item?.dataJson?.currencySymbol}
                                    {item?.totalAmount}
                                </span>
                            </div>
                            <div>
                                <Button onClick={() => downloadInvoice(item?.receiptPdf)} variant="contained">
                                    View Invoice
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* {openBillTo && (
        <BilledToInfo open={openBillTo} handleClose={handleBilledToPopup} />
      )} */}
            {/* {openBillMail && (
        <BillMail open={openBillMail} handleClose={handleBillMailPopup} />
      )} */}
        </>
    )
}

export default Billings
