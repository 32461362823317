import * as React from "react"
import Dialog from "@mui/material/Dialog"
import {styled} from "@mui/material/styles"
import DialogTitle from "@mui/material/DialogTitle"
import {Box, IconButton, Snackbar} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import PropTypes from "prop-types"
import DialogContent from "@mui/material/DialogContent"
import Card from "@mui/material/Card"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import "./styles.css"
import copy from "copy-to-clipboard"

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        // padding: theme.spacing(2),
        // height: theme.spacing(30),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

function BootstrapDialogTitle(props: any) {
    const {children, onClose, ...other} = props

    return (
        <Box sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </Box>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

type scriptProps = {
    clientPromptData?: any
    iFrameValue?: string
    popupValue?: string
    open?: boolean
    handleClose?: any
}

function GenerateScript({clientPromptData, iFrameValue, popupValue, open, handleClose}: scriptProps) {
    const [isOpen, setIsOpen] = React.useState(open)
    const [isCopy, setIsCopy] = React.useState(false)

    React.useEffect(() => {
        setIsOpen(open)
    }, [open])

    const copyToClipboard = (content) => {
        if (window.isSecureContext && !!navigator?.clipboard) {
            navigator?.clipboard?.writeText(content)
        } else {
            copy(content)
        }
        setIsCopy(true)
    }

    return (
        <>
            <BootstrapDialog
                // onClose={()=>setIsOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={isOpen}>
                <BootstrapDialogTitle
                    onClose={() => {
                        handleClose()
                    }}>
                    {clientPromptData.name.charAt(0).toUpperCase() + clientPromptData.name.slice(1)}
                </BootstrapDialogTitle>

                <DialogContent dividers>
                    <>
                        <h6>Popup script</h6>
                        <Box style={{position: "relative", marginBottom: 20}}>
                            {!!popupValue && (
                                <ContentCopyIcon
                                    onClick={() => copyToClipboard(popupValue)}
                                    style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 10,
                                        cursor: "copy",
                                        color: "grey"
                                    }}
                                />
                            )}
                            <Card variant="outlined" sx={{padding: 2, backgroundColor: "#f0f5f5"}}>
                                <code>{!!popupValue ? popupValue : "No script found"}</code>
                            </Card>
                        </Box>
                    </>

                    <>
                        <h6>Iframe embed script</h6>
                        <Box style={{position: "relative"}}>
                            {!!iFrameValue && (
                                <ContentCopyIcon
                                    onClick={() => copyToClipboard(iFrameValue)}
                                    style={{
                                        position: "absolute",
                                        top: 10,
                                        right: 10,
                                        cursor: "copy",
                                        color: "grey"
                                    }}
                                />
                            )}
                            <Card variant="outlined" sx={{padding: 2, backgroundColor: "#f0f5f5"}}>
                                <code>{!!iFrameValue ? iFrameValue : "No script found"}</code>
                            </Card>
                        </Box>
                    </>
                </DialogContent>
            </BootstrapDialog>
            {isCopy && (
                <Snackbar
                    message="Copy to Clipboard"
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    autoHideDuration={2000}
                    onClose={() => setIsCopy(false)}
                    open={isCopy}
                />
            )}
        </>
    )
}

export default React.memo(GenerateScript)
