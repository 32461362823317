import BaseService from "./Base"
import ApiUrls from "./ApiUrl"
import {ChatbotBodyParam} from "types/Chatbot"

export default class UserRolesService extends BaseService {
    async getRoleList(param: ChatbotBodyParam = {}) {
        try {
            const response = await this.post(ApiUrls.role + "list", param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
    async getUserList(param = {}) {
        try {
            const response = await this.post(ApiUrls.user + "list", param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
    async createUser(param = {}) {
        try {
            const response = await this.post(ApiUrls.user + "create", param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
    async updateUser(param = {}) {
        try {
            const response = await this.post(ApiUrls.user + "update", param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
    async switchActiveStatus(param = {}) {
        try {
            const response = await this.post(ApiUrls.activeSwitch, param)
            return response?.data
        } catch (error) {
            throw error
        }
    }
}
