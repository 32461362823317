import Routes from "./routes"
import {ScrollTop} from "./components"
import {ToastContainer} from "react-toastify"
import * as Sentry from "@sentry/react"
import {GlobalErrorBoundary} from "components/GlobalErrorBoundry"
import {useAuth, useIsStaff} from "hooks/useAuth"
import {useEffect} from "react"
import {useAppDispatch} from "redux/store"
import {getUserFromStore} from "redux/features/appStateSlice"
import {GoogleOAuthProvider} from "@react-oauth/google"

function App() {
    const auth = useAuth()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!auth.user) {
            dispatch(getUserFromStore({user: null, token: null, dispatch, isSetToken: false}))
        }
    }, [])

    return (
        <Sentry.ErrorBoundary fallback={(errorData) => <GlobalErrorBoundary {...errorData} />}>
            <ScrollTop>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
                    <Routes />
                    <ToastContainer />
                </GoogleOAuthProvider>
            </ScrollTop>
        </Sentry.ErrorBoundary>
    )
}

export default App
