import ApiUrls from "./ApiUrl"
import IntegrationService from "./Integration"

export default class ChatbotIntegrationService extends IntegrationService {
    async createWebHooks(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHookIntegration + "/" + "create/" + "webhook", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async listWebHooks(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHookIntegration + "/" + "list", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async deleteWebHook(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHookIntegration + "/" + "delete", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async updateWebHook(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHookIntegration + "/" + "update", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async listWebHookPlatform(params = {}) {
        try {
            const response = await this.post(ApiUrls.webHookPlatform + "/" + "list", params)
            return response
        } catch (error) {
            throw error
        }
    }

    async testIntegration(params = {}) {
        try {
            const response = await this.post(ApiUrls.testIntegration, params)
            return response
        } catch (error) {
            throw error
        }
    }
}
