import React from "react"
import cx from "classnames"
import styles from "./error.module.css"

type Props = {
    message: any
    className?: string
}

export function Error(props: Props) {
    return (
        <div className={cx(styles.errorWrapper, props.className)}>
            <div className={styles.warningIcon}>{/* <Icon icon="WARNING_TRIANGLE" color="#DF1642" /> */}</div>
            <p>{props.message}</p>
        </div>
    )
}
