import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "redux/store"
import {projectService} from "services"
import {MetaPagination} from "types/Others"
import {ProjectBodyParam, Projects} from "types/Projects"

type ProjectState = {
    projects: Array<Projects>
    meta: MetaPagination
    total: number
    currentProjectId: any
    isLoading: boolean
    isLoaded: boolean
}

export const getProjectsFromStore = createAsyncThunk(
    "auth/getProjectsFromStore",
    async (param: ProjectBodyParam = {}) => {
        return await projectService.getProjects(param)
    }
)

const initialState: ProjectState = {
    projects: [],
    meta: {},
    total: 0,
    currentProjectId: "",
    isLoading: true,
    isLoaded: false
}

export const projectSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setCurrentProjectId: (state, action: PayloadAction<any>) => {
            state.currentProjectId = action.payload || null
        },
        resetLoader: (state, action?) => {
            state.isLoaded = false
        },
        resetProject: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectsFromStore.fulfilled, (state, action) => {
                const {data, meta, total} = action.payload
                state.projects = data
                state.meta = meta
                state.total = total
                state.isLoading = false
                state.isLoaded = true
            })
            .addCase(getProjectsFromStore.rejected, (state, action) => {
                state.total = 0
                state.isLoading = false
                state.isLoaded = true
                state.projects = []
            })
    }
})

export const {setCurrentProjectId, resetLoader, resetProject} = projectSlice.actions

export default projectSlice.reducer
export const selectProjects = (state: RootState) => state.projectState
export const selectCurrentProjectId = (state: RootState) => state.projectState.currentProjectId
