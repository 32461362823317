import BaseService from "./Base"
import IntegrationService from "./Integration"
import AuthService from "./AuthService"
import ProjectService from "./ProjectService"
import ChatbotService from "./ChatbotService"
import ChatbotIntegrationService from "./ChatbotIntegrationService"
import ClientService from "./ClientService"
import PromptService from "./PromptService"
import PricingFeatureService from "./PricingFeatureService"
import PricingService from "./pricingService"
import InboxService from "./inboxService"
import ClientDashboardService from "./clientDashboard"
import DataParameterValidationService from "./dataParameterValidation"
import UserRolesService from "./UserRoleService"

const authService = new AuthService()
const baseService = new BaseService()
const integrationService = new IntegrationService()
const projectService = new ProjectService()
const chatbotService = new ChatbotService()
const chatbotIntegrationService = new ChatbotIntegrationService()
const clientService = new ClientService()
const promptService = new PromptService()
const pricingFeatureService = new PricingFeatureService()
const pricingService = new PricingService()
const inboxService = new InboxService()
const clientDashboardService = new ClientDashboardService()
const dataParameterValidationService = new DataParameterValidationService()
const userRolesService = new UserRolesService()

export {
    authService,
    baseService,
    integrationService,
    projectService,
    chatbotService,
    chatbotIntegrationService,
    clientService,
    promptService,
    pricingFeatureService,
    pricingService,
    inboxService,
    clientDashboardService,
    dataParameterValidationService,
    userRolesService
}
