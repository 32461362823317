import {useEffect, useMemo, useState} from "react"
import {DataGrid, GridColDef} from "@mui/x-data-grid"
import {Button, IconButton, Stack, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip} from "@mui/material"
import {styled} from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import PropTypes from "prop-types"
import "./styles.css"
import {useForm} from "react-hook-form"
import {Error} from "components/auth/Error"
import {Input} from "components/auth/Input"
import {useAppDispatch, useTypedSelector} from "redux/store"
import {CustomNoRowsOverlay} from "components/NORowsSVG"
import {getPricingFromStore, selectPricing} from "redux/features/pricingSlice"
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined"
import {pricingService} from "services"
import {toastError, toastSuccess} from "helpers/toastHelper"
import {getFormatedDate} from "helpers/common"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import DeleteConfirmation from "components/deleteConfirmation"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"

type Props = {}

const BootstrapDialog = styled(Dialog)(({theme}: any) => ({
    "& .MuiDialogContent-root": {
        padding: theme?.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme?.spacing(1)
    }
}))

function BootstrapDialogTitle(props: any) {
    const {children, onClose, ...other} = props

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

const inputsConfig = {
    title: {
        name: "title",
        placeholder: "Enter title",
        className: "form-control",
        type: "text",
        required: "Title field is required"
    },
    price: {
        name: "price",
        placeholder: "Enter monthly price",
        className: "form-control",
        type: "number",
        required: "Monthly price is required"
    },
    yearlyPrice: {
        name: "yearlyPrice",
        placeholder: "Enter yearly price",
        className: "form-control",
        type: "number",
        required: "Yearly price is required"
    },
    validityDuration: {
        name: "validityDuration",
        placeholder: "Enter duration in days",
        className: "form-control",
        type: "number",
        required: ""
    }
}

interface PricingState {
    open: boolean
    isEditMode: boolean
    isShowAlert: boolean
    isDeleting: boolean
    hasReOrder?: boolean
    pricingDetail: any
    page: number
    pageSize: number
    orderedPrices: any[]
}

const initialState: PricingState = {
    open: false,
    isEditMode: false,
    isShowAlert: false,
    isDeleting: false,
    hasReOrder: false,
    pricingDetail: null,
    page: 1,
    pageSize: 20,
    orderedPrices: []
}

const Pricing = (props: Props) => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors},
        clearErrors
    } = useForm()

    const [
        {open, isEditMode, pricingDetail, page, pageSize, isShowAlert, isDeleting, hasReOrder, orderedPrices},
        setState
    ] = useState(initialState)
    const dispatch = useAppDispatch()
    const pricing: Array<any> = useTypedSelector(selectPricing)

    const columns: GridColDef[] = [
        {
            field: "title",
            headerName: "Title",
            minWidth: 200,
            cellClassName: "capitalize",
            flex: 1
        },
        {field: "price", headerName: "Monthly Price", minWidth: 200, flex: 1},
        {field: "yearlyPrice", headerName: "Yearly Price", minWidth: 200, flex: 1},
        {field: "validityDuration", headerName: "Duration", minWidth: 200, flex: 1},
        {
            field: "createdAt",
            headerName: "Created At",
            valueFormatter: (params) => getFormatedDate(params?.value),
            minWidth: 200,
            flex: 1
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <Tooltip title="View" placement="right">
                            <IconButton
                                aria-label="view"
                                // color="primary"
                                onClick={(e) => handleEdit(e, cellValues)}>
                                <DriveFileRenameOutlineOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Pricing" placement="right">
                            <IconButton aria-label="delete" onClick={(e: any) => handleDelete(e, cellValues)}>
                                <span className="material-symbols-outlined">delete</span>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        }
    ]

    useEffect(() => {
        getPricing()
    }, [])

    useMemo(() => {
        if (!!pricing?.length) {
            const _pricing: any[] = pricing?.map((price, i) => ({
                id: price?.packageId,
                title: price?.title
            }))
            setState((prevState) => ({
                ...prevState,
                orderedPrices: _pricing
            }))
        }
    }, [pricing])

    const getPricing = async (p = page, ps = pageSize) => {
        dispatch(
            getPricingFromStore({
                range: {
                    page: p,
                    pageSize: ps
                }
            })
        )
        handleClose()
    }

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            open: false,
            isShowAlert: false,
            pricingDetail: null
        }))
    }

    const onChange =
        (name: any) =>
        ({target}: any) => {
            setValue(name, target.value)
        }

    const renderInput = (inputProps = {}) => {
        return (
            <Input
                {...{
                    register,
                    errors,
                    watch,
                    onChange,
                    ...inputProps
                }}
            />
        )
    }

    const handleDelete = (e, cellValue) => {
        e.preventDefault()
        setState((prevState) => ({
            ...prevState,
            isShowAlert: true,
            pricingDetail: cellValue?.row || null
        }))
    }

    const handleEdit = (e, cellValue) => {
        e.preventDefault()
        setState((prevState) => ({
            ...prevState,
            open: true,
            isEditMode: true,
            pricingDetail: cellValue?.row || null
        }))
        setValue("title", cellValue?.row?.title)
        setValue("price", cellValue?.row?.price)
        setValue("yearlyPrice", cellValue?.row?.yearlyPrice || 0)
        if (cellValue?.row?.slug === "free-trial") setValue("validityDuration", cellValue?.row?.validityDuration)
    }

    const handleDeleteHandler = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                isDeleting: true
            }))
            if (!!pricingDetail?.packageId) {
                const payload = {
                    packageId: pricingDetail?.packageId
                }
                const response = await pricingService.deletePricing(payload)
                if (response?.data.success) {
                    toastSuccess(response?.data?.message)
                    getPricing()
                    setState((prev) => ({
                        ...prev,
                        isDeleting: false,
                        pricingDetail: null,
                        isShowAlert: false
                    }))
                }
            }
        } catch (e) {
            setState((prevState) => ({
                ...prevState,
                isDeleting: false
            }))
            toastError(e["response"]?.data?.message)
        }
    }

    const openPlanPopup = () => {
        setState((prevState) => ({
            ...prevState,
            open: true,
            isEditMode: false,
            pricingDetail: null
        }))
        setValue("title", "")
        setValue("price", "")
        setValue("yearlyPrice", "")
        setValue("validityDuration", "")
    }

    const onModelChange = (event) => {
        setState((prevState) => ({
            ...prevState,
            page: event.page + 1,
            pageSize: event.pageSize
        }))

        getPricing(event.page + 1, event.pageSize)
    }

    const onSubmit = async ({title, price, yearlyPrice, validityDuration = 0}) => {
        try {
            const payload = {
                title: title,
                price: Number(price),
                yearlyPrice: Number(yearlyPrice) || 0
            }
            if (!!pricingDetail) {
                payload["packageId"] = pricingDetail.packageId
                if (pricingDetail?.slug === "free-trial") payload["validityDuration"] = validityDuration
            } else {
                payload["features"] = []
            }
            const response = !!pricingDetail
                ? await pricingService.updatePricing([payload])
                : await pricingService.createPricing(payload)
            if (response?.data?.success) {
                toastSuccess(
                    response?.data?.message
                        ? response?.data?.message
                        : !!pricingDetail
                          ? "Packages updated successfully!"
                          : "Packages created successfully!"
                )
                getPricing()
                setState((prevState) => ({
                    ...prevState,
                    open: false
                }))
            }
        } catch (error) {}
    }

    // Re-order code start
    const openReOrderPopup = (isTrue = false) => {
        setState((prevState) => ({
            ...prevState,
            hasReOrder: isTrue
        }))
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const items = reorder(orderedPrices, result.source.index, result.destination.index)

        setState((prevState) => ({
            ...prevState,
            orderedPrices: items
        }))
    }

    const onClickUpdate = async () => {
        if (!orderedPrices?.length) return
        try {
            const data = orderedPrices?.map((item, index) => ({
                packageId: item.id,
                positionIndex: index + 1
            }))
            const payload = {
                positions: data
            }
            const response = await pricingService.pricingUpdateSequence(payload)
            if (response?.data?.success) {
                toastSuccess(response?.data?.message ?? "Package sequence updated successfully!")
                getPricing()
                openReOrderPopup()
            }
        } catch (err) {}
    }

    return (
        <>
            <div className="project_lists">
                <div className="container-fluid">
                    <div className="chat_title">
                        <h4 className="my-4">Packages</h4>
                        <div className="flex gap-4">
                            <Button
                                variant="outlined"
                                className="chat_gray_button"
                                onClick={() => openReOrderPopup(true)}>
                                <span className="material-symbols-outlined">reorder</span>
                                {"  "} Re-order Packages
                            </Button>
                            <Button variant="outlined" className="chat_add_button" onClick={openPlanPopup}>
                                <AddOutlinedIcon fontSize="inherit" /> {"  "} Add Package
                            </Button>
                        </div>
                    </div>
                    <div style={{height: "75vh", width: "100%", backgroundColor: "white"}}>
                        <DataGrid
                            rows={pricing ?? []}
                            columns={columns}
                            getRowId={(row) => row?.packageId}
                            slots={{
                                columnMenu: CustomNoRowsOverlay
                            }}
                            onPaginationModelChange={onModelChange}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: page, pageSize: pageSize}
                                }
                            }}
                            pageSizeOptions={[10, 20, 30, 40, 50]}
                        />
                    </div>
                </div>
            </div>

            {!!open && (
                <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <BootstrapDialogTitle onClose={handleClose}>
                        {isEditMode ? "Update Package" : "Create new package"}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-project">
                            <div className="row">
                                <div className="form-group col-lg-12">
                                    <label htmlFor="">Title</label>
                                    {renderInput(inputsConfig.title)}
                                </div>
                                {!!errors.title && <Error message={errors.title.message} />}

                                <div className="form-group col-lg-12 mt-3">
                                    <label htmlFor="">Monthly Price</label>
                                    {renderInput(inputsConfig.price)}
                                </div>
                                {!!errors.price && <Error message={errors.price.message} />}

                                <div className="form-group col-lg-12 mt-3">
                                    <label htmlFor="">Yearly Price</label>
                                    {renderInput(inputsConfig.yearlyPrice)}
                                </div>
                                {!!errors.yearlyPrice && <Error message={errors.yearlyPrice.message} />}

                                {!!pricingDetail && pricingDetail?.slug === "free-trial" && (
                                    <div className="form-group col-lg-12 mt-3">
                                        <label htmlFor="">Duration</label>
                                        {renderInput(inputsConfig.validityDuration)}
                                    </div>
                                )}

                                <div className="form-group mt-4 col-lg-12">
                                    <DialogActions className="p-0">
                                        <Button
                                            // disabled={isSubmitting}
                                            className="save-btn mx-auto"
                                            type="submit">
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                </BootstrapDialog>
            )}

            {!!hasReOrder && (
                <BootstrapDialog
                    onClose={() => openReOrderPopup(false)}
                    aria-labelledby="customized-dialog-title"
                    open={hasReOrder}>
                    <BootstrapDialogTitle onClose={() => openReOrderPopup(false)}>
                        Re-order Packages
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className="row">
                            <div className="col-lg-12">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="drag-main">
                                                {orderedPrices?.map((item, index) => (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={`${item.id}_${item?.title}`}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={
                                                                    snapshot.isDragging
                                                                        ? "active-item active-drag"
                                                                        : "active-drag inactive-item"
                                                                }>
                                                                {item.title}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>

                            <div className="form-group mt-4 col-lg-12">
                                <DialogActions className="p-0">
                                    <Button onClick={onClickUpdate} className="save-btn mx-auto" type="submit">
                                        Update
                                    </Button>
                                </DialogActions>
                            </div>
                        </div>
                    </DialogContent>
                </BootstrapDialog>
            )}

            {isShowAlert && (
                <DeleteConfirmation
                    onCancel={() => {
                        handleClose()
                    }}
                    isOpen={isShowAlert}
                    onAccept={handleDeleteHandler}
                    onLoading={isDeleting}
                />
            )}
        </>
    )
}

export default Pricing
