import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "redux/store"
import {promptService} from "services"
import {ClientsBodyParam} from "types/Clients"
import {MetaPagination} from "types/Others"

type PromptsState = {
    prompts: Array<any>
    meta: MetaPagination
    total: number
    isLoading: boolean
}

export const getPromptsFromStore = createAsyncThunk(
    "auth/getPromptsFromStore",
    async (param: ClientsBodyParam = {}) => {
        return await promptService.getPrompts(param)
    }
)

const initialState: PromptsState = {
    prompts: [],
    meta: {},
    total: 0,
    isLoading: true
}

export const promptSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        resetPrompt: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPromptsFromStore.fulfilled, (state, action) => {
                const {data, meta, total} = action.payload
                state.prompts = data
                state.meta = meta
                state.total = total
                state.isLoading = false
            })
            .addCase(getPromptsFromStore.rejected, (state, action) => {
                state.isLoading = false
                state.total = 0
            })
    }
})

export const {resetPrompt} = promptSlice.actions

export default promptSlice.reducer
export const selectPrompts = (state: RootState) => state.promptState
