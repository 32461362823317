import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {RootState} from "redux/store"
import {chatbotService} from "services"
import {MetaPagination} from "types/Others"
import {QABodyParam} from "types/questionAnswer"

type QuestionAnswerState = {
    qa: Array<any>
    meta: MetaPagination
    total: number
    isLoading: boolean
}

export const getQuestionAnswerFromStore = createAsyncThunk(
    "auth/getQuestionAnswerFromStore",
    async (param: QABodyParam = {}) => {
        return await chatbotService.QuestionAnswerList(param)
    }
)

const initialState: QuestionAnswerState = {
    qa: [],
    meta: {},
    total: 0,
    isLoading: true
}

export const questionAnswerSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        resetQusAns: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionAnswerFromStore.fulfilled, (state, action) => {
                const {data, meta, total} = action.payload.data
                state.qa = data
                state.meta = meta
                state.total = total
                state.isLoading = false
            })
            .addCase(getQuestionAnswerFromStore.rejected, (state, action) => {
                state.isLoading = false
                state.total = 0
            })
    }
})

export const {resetQusAns} = questionAnswerSlice.actions

export default questionAnswerSlice.reducer
export const selectQuestionAnswer = (state: RootState) => state.questionAnswerState
