import {colors} from "@mui/material"

const colorConfigs = {
    default: {
        sidebar: {
            bg: "#f2f2f2",
            color: "#2a2a2a",
            hoverColor: "#262626",
            hoverBg: "#e6e6e6",
            activeBg: "#f9f9f9",
            activeText: "#2a2a2a",
            iconColor: "#333333",
            activeIconColor: "#ee783a",
            gradientColorSidebar: "#f2f2f2"
        },
        toolbar: {
            bg: "#f2f2f2",
            color: "#2a2a2a",
            shadow: "0px 0px 2px 0px #838383"
        },
        topbar: {
            bg: "#000",
            color: "#000",
            topbarGradient: "#f2f2f2"
        }
    },
    mainBg: colors.grey["100"]
}

export default colorConfigs
